<template>
  <div class="doc-container container">
    <h2 class="h2_title">知识产权投诉指引</h2>
    <p>
      我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为网站<a href="https://bytefun.link/">https://bytefun.link/</a
      >的内容侵犯其商标权、著作权（盗用图片、盗用视频、文字或艺术作品侵权等）、专利权（外观、实用新型、发明等），可以通过邮箱：bytefun666@gmail.com投诉，我们将在收到知识产权权利人合格通知后依据相应的法律法规以及平台规则及时处理。
    </p>
    <h3>一、 投诉指引</h3>
    <p>权利人针对佰悦互娱网站内的经营者所提供的商品或服务，或提供服务所展示的商品信息有可能侵犯其知识产权，即专利权、商标权和著作权等，可发起投诉以维护自身合法权益。</p>
    <b>投诉人应当提供相关文件：</b>
    <ol>
      <li>权利人身份主体证明；</li>
      <li>委托代理人投诉的，代理人需出具代理人身份主体证明、权利人的授权委托书，授权委托书应包含授权知识产权维权投诉等相关内容、权利人的签字并加盖其法人公章或其自然人签字。</li>
      <li>知识产权侵权投诉单（投诉单模板见附件）</li>
      <li>
        权属信息并提供相关证明文件：
        <ol>
          <li>
            针对商标侵权的投诉，需要提交以下全部权属证明原件的彩色扫描件：
            <ol>
              <li>商标注册证；</li>
              <li>商标转让证明（如有）；</li>
              <li>商标变更证明（如有）；</li>
              <li>商标续展证明（如有）；</li>
            </ol>
          </li>
          <li>
            针对专利权侵权的投诉，需要提交以下全部权属证明原件的彩色扫描件：
            <ol>
              <li>专利证书，包括权利要求书、说明书、附图等；</li>
              <li>专利登记簿副本；</li>
              <li>涉及实用新型和外观设计专利权的投诉，需提交专利权评价报告；</li>
            </ol>
          </li>
          <li>
            针对著作权侵权的投诉，可以择一提交以下权属证明：
            <ol>
              <li>著作权登记证书原件扫描件；</li>
              <li>被盗用图片或者被盗用视频的原始图片、视频拍摄底稿等证明材料；</li>
              <li>其他可以证明投诉人拥有合法、完整著作权的证明材料的原件彩色扫描件；</li>
            </ol>
          </li>
          <li>上传与涉嫌侵权商品有关的生效司法判决、行政裁定等证明文件（若有）；</li>
        </ol>
      </li>
    </ol>
    <b>投诉文件的要求</b>
    <p>
      投诉人所提交的身份信息文件，应当清晰完整，每一份文件上均应当有投诉人亲笔签名（对自然人）或加盖投诉人公章（对法人或其他组织）。有效的侵权通知应当包括构成侵权的初步证据，包括但不限于以下信息或相关文件：
    </p>
    <ol>
      <li>明确填写涉嫌侵权的具体链接；有多个涉嫌侵权链接的，请逐一填写每个链接；</li>
      <li>明确指出涉嫌侵权商品侵犯上述知识产权的具体内容和理由，并写明诉求，涉及专利侵权投诉需要提交详细的专利侵权对比分析；</li>
      <li>其他能够证明存在侵权行为的补充投诉信息或证据材料。</li>
      <li>投诉人对投诉资料及通知的真实性、合法性、准确性负责的承诺。</li>
    </ol>
    <b>对投诉文件的处理</b>
    <ol>
      <li>
        在收到符合上述知识产权相关投诉时，佰悦互娱网站将根据您提交的构成侵权的初步证据，在合理时间内删除或断开涉嫌侵权的内容。如不符合上述条件，我们会将投诉驳回并请您补充提供相应信息和材料，但暂时不会采取包括删除或者断开等相应措施。
      </li>
      <li>由于投诉涉及的内容不同，复杂程度亦不同，故所需周期也不同，请您耐心等待，请勿重复多次提交相同投诉内容。</li>
    </ol>
    <b>投诉人承诺</b>
    <ol>
      <li>
        投诉人保证提供信息的真实性、合法性、准确性，由于投诉人提供信息不实导致的任何后果及责任由投诉人承担。平台方对提供虚假材料的投诉人有权采取限制投诉、屏蔽账号等必要措施，并保留依法追责的权利。
      </li>
      <li>投诉人授权并同意平台方为处理投诉的目的使用投诉人提供的信息和文件，投诉人同意平台方将该类信息及文件转发至被投诉人。</li>
      <li>投诉人授权并同意平台方为处理投诉的目的将投诉人提供的信息和文件转交给相关行政机关、权威的第三方专业机构或人民调解组织协助判断或调解。</li>
      <li>投诉人授权并同意平台方将投诉涉及的信息和文件转交给相关司法机关、行政执法机关用于配合调查取证等用途。</li>
      <li>由于投诉人提供材料不全导致平台方无法及时受理投诉，视为平台方未知晓该投诉，平台方不承担由此产生的任何直接或间接责任。</li>
      <li>投诉人应当就其投诉一次性提供足够的证明材料，对于投诉时未提供，而在随后的行政或司法程序中提出的新材料，投诉人不得主张平台方审核不力，也不得据此要求平台方承担任何直接或间接责任。</li>
      <li>因投诉人通知错误给被投诉人造成损害或者因投诉人恶意发出错误通知，造成被投诉人损失的，被投诉人可根据相关法律规定要求投诉人承担相应赔偿责任，平台方不承担由此产生的任何直接或间接责任。</li>
      <li>平台方有权根据相关法律的规定公示双方的投诉和申诉信息。</li>
    </ol>
    <b>申诉指引</b>
    <p>针对投诉人的维权投诉，被投诉人如认为自己并未侵权可发起的反驳投诉的申诉。</p>
    <b>申诉人可以提交如下信息和材料：</b>
    <ol>
      <li>
        针对商标侵权的投诉，可提交如下证明材料：
        <ol>
          <li>商品的授权文件以及可证明商品合法来源的凭证；</li>
          <li>被投诉商品的自有商标证书（如有）；</li>
          <li>被投诉商品相关的生效司法判决、行政裁定等证明文件（如有）；</li>
          <li>其他能够证明不存在侵权行为的补充信息或证据材料。</li>
        </ol>
      </li>
      <li>
        针对专利侵权的投诉，可提交如下证明材料：
        <ol>
          <li>被控侵权商品来自于投诉人的凭证；</li>
          <li>被控侵权商品系根据申诉人自有专利权或他人专利权合法生产的凭证，包括权属证明和/或授权证明；</li>
          <li>被投诉商品与投诉人外观设计专利不相同且不相近似的对比说明；/或授权证明；</li>
          <li>被投诉商品与投诉人发明、实用新型专利采用的技术不同的对比说明；</li>
          <li>专利申请日之前已公开相同技术方案或外观设计的文献资料或销售记录；</li>
          <li>申诉人或第三人针对所涉及专利的有效性提出质疑，并已提出司法、行政等程序的凭证（如《无效申请受理通知书》、无效申请的证据资料、无效申请决定书等）；</li>
          <li>其他能够证明不存在侵权行为的补充信息或证据材料。</li>
        </ol>
      </li>
      <li>
        针对著作权侵权的投诉，可提交如下证明材料：
        <ol>
          <li>著作权许可协议，证明商品合法来源的凭证，著作权登记证书或其他相关权利证明等；</li>
          <li>针对盗用图片的投诉，可提交被投诉图片设计原稿及其他可证明被投诉图片系其自行创作的证明材料；</li>
          <li>针对盗用视频的投诉，可提交被投诉视频的独立拍摄底稿及其他可证明被投诉视频系其自行创作的证明材料；</li>
          <li>被投诉商品相关的生效司法判决、行政裁定等证明文件（如有）；</li>
          <li>其他能够证明不存在侵权行为的补充信息或证据材料。</li>
        </ol>
      </li>
      <li>详细说明不构成侵权的书面理由。</li>
      <li>申诉人对申诉资料及通知的真实性、合法性、准确性负责的承诺书。</li>
    </ol>
    <p>申诉人必须按照上述顺序提交所有文件，不得遗漏或缺失，每一份文件中记载的事项必须清晰完整，并在每一份文件上签署申诉人亲笔签名（对自然人）或加盖申诉人公章（对法人或其他组织）。</p>
    <b>申诉人承诺</b>
    <ol>
      <li>申诉人保证提供信息的真实性、合法性、准确性、即时性，由于申诉人提供信息不实导致的任何后果及责任由申诉人承担。</li>
      <li>申诉人授权并同意平台方为处理申诉的目的使用申诉人提供的信息和文件，申诉人同意平台将该类信息及文件转发至投诉人。</li>
      <li>申诉人授权并同意平台方为处理投诉的目的将申诉人提供的信息和文件转交给相关行政机关、权威的第三方专业机构或人民调解组织协助判断或调解。</li>
      <li>申诉人授权并同意平台方将投诉涉及的信息和文件转交给相关司法机关、行政执法机关用于配合调查取证等用途。</li>
      <li>申诉人应当就其申诉一次性提供足够的证明材料，对于申诉时未提供，而在随后的行政或司法程序中提出的新材料，申诉人不得主张平台方审核不力，也不得据此要求平台方承担任何直接或间接责任。</li>
      <li>因投诉人通知错误给申诉人造成损害或者因投诉人恶意发出错误通知，造成申诉人损失的，申诉人可根据相关法律规定要求投诉人承担相应赔偿责任，平台方不承担由此产生的任何直接或间接责任。</li>
      <li>平台方有权根据相关法律的规定公示双方的投诉和申诉信息。</li>
    </ol>
    <b>申述渠道（以下渠道二选一）</b>
    <ol>
      <li>发给平台管理方：bytefun666@gmail.com</li>
      <li>知识产权投诉举报热线：12315</li>
    </ol>

    <b>附件下载：</b>
    <ol>
      <li><a :href="onAnnexDownloadBtn('/file/知识产权侵权投诉单.docx')" target="_blank" download="知识产权侵权投诉单.docx">知识产权侵权投诉单.docx</a></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'HelpDoc2',
  methods: {
    // 处理函数,当附件下载按钮被点击时触发
    onAnnexDownloadBtn(path) {
      return `http://${window.location.host}${path}`
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-container {
  margin: 2rem auto !important;
  min-height: 72vh;
  a {
    color: #007bff;
    &:hover {
      text-decoration: underline;
    }
  }
  //   公共样式
  ol {
    margin-left: 2rem;
  }
  // 公共样式
  h2 {
    color: black;
  }
}
</style>
